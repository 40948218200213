import Vue from 'vue'
import Vuex from 'vuex'
import api from '../api'
import storage from '../utils/storage'
Vue.use(Vuex)

const TYPES = {
	'TOKEN': 'TOKEN',
	'USER_INFO': 'USER_INFO',
	'MEMBER_INFO': 'MEMBER_INFO',
	'APPOINTMENTS': 'APPOINTMENTS',
	'LOADING': 'LOADING',
	'DOWNLOAD_URL': 'DOWNLOAD_URL',

	'TEMPORARY_TOKEN': 'TEMPORARY_TOKEN',
	'TEMPORARY_USER': 'TEMPORARY_USER',
	'TEMPORARY_MIDS': 'TEMPORARY_MIDS',
	'CHANGE_SCORE_LANGUAGE': 'CHANGE_SCORE_LANGUAGE',
	'SHOW_SCORE_CARD': 'SHOW_SCORE_CARD'
}
export default new Vuex.Store({
	state: {
		token: '',
		minfo: {},
		appointments: [],
		loading: false,


		iosUrl: "",
		androidUrl: "",
		androidUrlBak:"",
		downloadPageUrl: "",

		temporaryToken: "",
		temporaryUser: {},
		temporaryChat: {},
		temporaryMids: {},
		scoreLanguage: 'zh_CN',
		scoreCardShow: true
	},
	mutations: {
		[TYPES.TOKEN](state, token) {
			state.token = token
		},
		[TYPES.MEMBER_INFO](state, minfo) {
			state.minfo = minfo
		},
		[TYPES.APPOINTMENTS](state, appointments) {
			state.appointments = appointments
		},
		[TYPES.LOADING](state, loading) {
			state.loading = loading
		},
		[TYPES.DOWNLOAD_URL](state, { iosUrl, androidUrl,androidUrlBak, downloadPageUrl }) {
			state.iosUrl = iosUrl
			state.androidUrl = androidUrl
			state.downloadPageUrl = downloadPageUrl
			state.androidUrlBak=androidUrlBak
		},
		[TYPES.TEMPORARY_TOKEN](state, token = '') {
			state.temporaryToken = token
		},
		[TYPES.TEMPORARY_USER](state, { minfo, chat }) {
			state.temporaryUser = minfo
			state.temporaryChat = chat
		},
		[TYPES.TEMPORARY_MIDS](state, data) {
			Object.assign(state.temporaryMids, data)
		},
		[TYPES.CHANGE_SCORE_LANGUAGE](state, data) {
			state.scoreLanguage = data
		},
		[TYPES.SHOW_SCORE_CARD](state, data) {
			state.scoreCardShow = data
		},
	},
	actions: {
		async setScoreLanguage(context, language) {
			context.commit(TYPES.CHANGE_SCORE_LANGUAGE, language.target.value)
		},
		async changeScoreCardShow(context, show) {
			context.commit(TYPES.SHOW_SCORE_CARD, show.target.value)
		},
		async setToken(context, token = false) {
			context.commit(TYPES.TOKEN, token)
			if (token) {
				context.dispatch('getMemberInfo')
			}
		},
		async getMemberInfo(context) {
			const response = await api.get('/get_user')
			if (response && response.code == 200) {
				context.commit(TYPES.MEMBER_INFO, response.data)
				return response.data
			}
			return Promise.reject(false)
		},
		async setMemberInfo(context, minfo = {}) {
			context.commit(TYPES.MEMBER_INFO, minfo)
		},
		async syncMemberInfo(context) {
			if (context.state.minfo.uid) {
				return context.state.minfo
			}
			return context.dispatch('getMemberInfo')
		},
		async getAppointments(context) {
			const response = await api.get('/web/appointment/list')
			if (response) {
				const appointments = []
				Object.keys(response.data).forEach(date => {
					appointments.push({
						date,
						lists: response.data[date]
					})
				})
				context.commit(TYPES.APPOINTMENTS, appointments)
			}
		},
		async setLoading(context, show) {
			context.commit(TYPES.LOADING, show)
		},
		async getDownloadUrls(context) {
			const response = await api.get('/web/download')
			if (response.code == 200) {
				const { android_url,android_url_bak, ios_url, download_page_url } = response.data
				context.commit(TYPES.DOWNLOAD_URL, {
					iosUrl: ios_url,
					androidUrl: android_url,
					androidUrlBak: Array.isArray(android_url_bak) && android_url_bak.length > 0 ? android_url_bak[0] : '',
					downloadPageUrl: download_page_url
				})
			}
			return response.data
		},
		 async initTemporary(context, uid) {
			let randomKey = storage.get('randomKey')
			if (randomKey) {
				const res = await api.post('/temporary_chat', { uid, only: randomKey })
				if (res.code == 200) {
					const { user, chat } = res.data
					const { token, ...minfo } = user
					storage.set('temporary_token', token)
					storage.set('temporary_user', minfo)
					storage.set('temporary_chat', chat)
					let anchors = storage.get('temporary_anchors') || [] // 有聊过天的主播
					if (!anchors.includes(uid)) {
						anchors.push(uid)
						storage.set('temporary_anchors', anchors)
					}
					let key = `temporary_message_${uid}`
					let mid = storage.get(key) || false
					if (mid) {
						context.commit(TYPES.TEMPORARY_MIDS, { [key]: mid })
					}
					context.commit(TYPES.TEMPORARY_TOKEN, token)
					context.commit(TYPES.TEMPORARY_USER, { minfo, chat })
					// subscribe_presence(minfo.uid)
				}
			}
		},
		async getTemporaryMessages(context, params) {
			let { temporaryToken, temporaryChat } = context.state
			if (temporaryToken && temporaryChat.uid) {
				const res = await api.get(`/get_msg/${temporaryChat.uid}`, {
					params: Object.assign({
						type: 1,
						limit: 50,
						source: 'h5'
					}, params),
					headers: {
						authorization: temporaryToken
					}
				})
				return Promise.resolve(res.data || [])
			} else {
				return Promise.resolve([])
			}
		},
		async setTemporaryMid(context, { uid, mid }) {
			let key = `temporary_message_${uid}`
			storage.set(key, mid)
			context.commit(TYPES.TEMPORARY_MIDS, { [key]: mid })
		},
		async clearTemporaryToken(context) {
			context.commit(TYPES.TEMPORARY_TOKEN, '')
		}
	}
})
